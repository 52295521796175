<template>
  <div>
    <loading :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="fullPage">
    </loading>

    <div class="row justify-content-md-center">

      <!-- Side Bar -->
      <div class="col-12">
        <filter-side-bar>
          <template slot="ContentSideBar">
            <form id="processes-list-filter" ref='processesListFilter' v-on:submit.prevent="applyFilter()">
              <h5 class="mb-0">Filtrar por:</h5>

            </form>
          </template>
        </filter-side-bar>
      </div>

      <!-- Navbar -->
      <div class="col-12 mb-3">
        <div class="card no-bottom-margin">
          <div class="section-sub-menu">
            <div class="section-sub-menu">
              <ul class="nav line-tabs borderless flex-center">
                <li class="nav-item">
                  <a class="nav-link" v-bind:class="{ active: filter.id_tipo === null }"
                     v-on:click="applyStatusFilter(null)" data-toggle="tab">Todos</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" v-bind:class="{ active: filter.id_tipo === null }"
                     v-on:click="applyStatusFilter(0)" data-toggle="tab">Informados</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" v-bind:class="{ active: filter.id_tipo === null }"
                     v-on:click="applyStatusFilter(1)" data-toggle="tab">Aprovados</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" v-bind:class="{ active: filter.id_tipo === null }"
                     v-on:click="applyStatusFilter(2)" data-toggle="tab">Recusados</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <!-- Main card -->
      <div class="col-12 col-sm-12">
        <div class="row">
          <div class="col-12 px-0">
            <div class="card">
              <div class="card-body">

                <!--  Header and Actions -->
                <div class="flexbox px-0 py-3 mb-3">
                  <div class="d-flex align-items-center">
                    <h5 class="box-title mb-3 mt-1">Moderação de Avaliações</h5>
                  </div>
                  <div class="btn-group" role="group" aria-label="false">
                    <router-link v-show="isValuationAdmin || isSuperAdmin"
                      :to="{name: 'ValuationNew'}" class="btn btn-sm btn-outline-light pull-right">
                      Novo
                    </router-link>

                    <router-link v-show="isValuationAdmin || isSuperAdmin"
                      :to="{name: 'ValuationReasonIndex'}" class="btn btn-sm btn-outline-light pull-right">
                      Configurações
                    </router-link>
                  </div>
                </div>

                <!-- Table -->
                <div class="table-responsive px-0">
                  <vuetable ref="vuetable"
                    :noDataTemplate="this.$i18n.t('noting to result')"
                    :api-url="endpoint"
                    :fields="vTableFields"
                    :sort-order="sortOrder"
                    :css="css.table"
                    pagination-path="pagination"
                    :per-page="20"
                    :multi-sort="multiSort"
                    :append-params="filter"
                    multi-sort-key="shift"
                    :http-options="httpOptions"
                    track-by="id_process"
                    @vuetable:pagination-data="onPaginationData"
                    @vuetable:loading="onLoading"
                    @vuetable:loaded="onLoaded"
                    @vuetable:cell-clicked="onRowClicked">
                    <template slot="actions" slot-scope="props">
                      <div class="custom-actions">
                        <a class="btn btn-link"
                           v-bind:href="mountLink('ValuationView', props.rowData)">
                          <i class="text-primary rc-Ativo-210 font-20"></i>
                        </a>
                      </div>
                    </template>
                  </vuetable>
                </div>
                <vuetable-pagination ref="pagination"
                  :css="css.pagination" @vuetable-pagination:change-page="onChangePage"
                ></vuetable-pagination>
                <!-- END Table -->

              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
// Basic
import Vue from 'vue'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import FilterSideBar from '@/components/FilterSideBar.vue'

// Table
import Vuetable, { VuetablePagination } from 'vuetable-2'
// eslint-disable-next-line
import '@/assets/vendor/reflow-table/js/reflow-table.js'
import '@/assets/vendor/reflow-table/css/reflow-table.css'

// Services
import ValuationService from '@/services/ValuationService'

import ModerationModal from '@/views/Valuation/Moderation.vue'

export default {
  name: 'ValuationReasonList',
  metaInfo () {
    return {
      titleTemplate: this.$i18n.t('valuation.config') + ' - %s'
    }
  },
  data () {
    return {
      isLoading: false,
      fullPage: true,
      filter: {},
      endpoint: process.env.VUE_APP_APIENDPOINT + 'v2/valuation',
      multiSort: true,
      statusList: [],
      vTableFields: [
        {
          name: 'id',
          title: this.$i18n.t('form.valuation.id'),
          sortField: 'id',
          dataClass: 'text-center',
          titleClass: 'text-center',
          formatter: (value) => this.$util.formatEmpty(value)
        },
        {
          name: 'data_criacao',
          title: this.$i18n.t('form.valuation.date'),
          sortField: 'data_criacao',
          dataClass: 'text-center',
          titleClass: 'text-center',
          formatter: (value) => this.$util.formatDate(value)
        },
        {
          name: 'tipo',
          title: this.$i18n.t('form.valuation.type'),
          sortField: 'tipo',
          dataClass: 'text-center',
          titleClass: 'text-center',
          formatter: (value) => this.$util.formatEmpty(value)
        },
        {
          name: 'origem',
          title: this.$i18n.t('form.valuation.origin'),
          sortField: 'origem',
          dataClass: 'text-center',
          titleClass: 'text-center',
          formatter: (value) => this.$util.formatEmpty(value)
        },
        {
          name: 'protagonistas',
          title: this.$i18n.t('form.valuation.protagonists'),
          sortField: 'protagonistas',
          dataClass: 'text-center',
          titleClass: 'text-center',
          formatter: (value) => this.$util.formatEmpty(value)
        },
        {
          name: 'equipes',
          title: this.$i18n.t('form.valuation.teams'),
          sortField: 'equipes',
          dataClass: 'text-center',
          titleClass: 'text-center',
          formatter: (value) => this.$util.formatEmpty(value)
        },
        {
          name: 'moderadores',
          title: this.$i18n.t('form.team.moderators'),
          sortField: 'moderadores',
          dataClass: 'text-center',
          titleClass: 'text-center',
          formatter: (value) => this.$util.formatEmpty(value)
        },
        {
          name: 'situacao',
          title: this.$i18n.t('form.valuation.status'),
          sortField: 'situacao',
          dataClass: 'text-center',
          titleClass: 'text-center',
          formatter: (value) => this.formatStatus(value)
        },
        {
          name: 'peso',
          title: this.$i18n.t('form.valuation.weight'),
          sortField: 'peso',
          dataClass: 'text-center',
          titleClass: 'text-center',
          formatter: (value) => this.$util.formatEmpty(value)
        // },
        // {
        //   name: 'actions',
        //   title: 'Ações',
        //   width: '100px',
        //   dataClass: 'text-center',
        //   titleClass: 'text-center'
        }
      ],
      sortOrder: [
        { field: 'data_criacao', direction: 'desc' }
      ],
      httpOptions: {
        headers: {
          'Authorization': 'Bearer ' + global.instanceApp.$Cookie.get('_userAuth_')
        }
      },
      css: {
        table: {
          tableClass: 'table table-hover align-middle table-clickable',
          rowCss: 'teste',
          loadingClass: 'loading',
          ascendingIcon: 'ti-angle-double-up',
          descendingIcon: 'ti-angle-double-down',
          handleIcon: 'glyphicon glyphicon-menu-hamburger'
        },
        pagination: {
          infoClass: 'pull-left',
          wrapperClass: 'vuetable-pagination text-center',
          activeClass: 'page-item active',
          disabledClass: 'disabled',
          pageClass: 'btn btn-border',
          linkClass: 'btn btn-border',
          icons: {
            first: '',
            prev: '',
            next: '',
            last: ''
          }
        }
      }
    }
  },
  components: {
    Loading,
    FilterSideBar,
    Vuetable,
    VuetablePagination
  },
  beforeMount () {
  },
  mounted () {
    this.$store.dispatch('SetShowFilter', false)
    this.$store.dispatch('setShowSearch', false)
  },
  created () {
    this.loadStatusList(true)
  },
  methods: {
    mountLink (itemName, object) {
      return this.$router.resolve({ name: itemName, params: { id: object.id } }).href
    },
    buildFilters () {
      // Vendedor
      // this.filter.vendedor = (this.filters.filEmployees) ? this.filters.filEmployees.map(x => (x.id)) : []

      // Processo
      // if (this.filters.filProcesso) {
      //   this.filter.internal_code = this.filters.filProcesso
      // } else {
      //   this.filter.internal_code = null
      // }

      // ETD
      // this.filter.etd_begin_date = this.filters.filEtdInicio
      // this.filter.etd_end_date = this.filters.filEtdFim

      // Operação
      // this.filter.operation = []
      // if (this.filters.filTipoOperacaoModalExportacao) {
      //   this.filter.operation.push(this.filters.filTipoOperacaoModalExportacao)
      // }
      // if (this.filters.filTipoOperacaoModalImportacao) {
      //   this.filter.operation.push(this.filters.filTipoOperacaoModalImportacao)
      // }
    },
    applyFilter () {
      // Operation Filter
      // this.filters.filTipoOperacaoModalExportacao = this.$refs.operation_expo.checked ? parseInt(this.$refs.operation_expo.value) : null
      // this.filters.filTipoOperacaoModalImportacao = this.$refs.operation_impo.checked ? parseInt(this.$refs.operation_impo.value) : null

      this.buildFilters()
      this.$store.dispatch('setAppliedFilter', this.filters)

      this.hideQuickSidebar()
      this.$refs.vuetable.refresh()
    },
    applyStatusFilter (idStatus) {
      event.preventDefault()
      this.filter.id_status = idStatus
      this.$refs.vuetable.refresh()
    },
    resetFilter (event) {
      event.preventDefault()
      this.hideQuickSidebar()

      // this.filters.filEmployees = []
      // this.filters.filProcesso = null
      // this.filters.filEtdInicio = null
      // this.filters.filEtdFim = null
      // this.filters.filDdlInicio = null
      // this.filters.filDdlFim = null

      this.$store.dispatch('setAppliedFilter', this.filters)

      this.buildFilters()
      this.$refs.vuetable.refresh()
    },
    hideQuickSidebar () {
      const sideBar = document.querySelector('#quick-sidebar')
      document.body.classList.remove('has-backdrop')
      sideBar.classList.remove('shined')
    },
    onPaginationData (paginationData) {
      this.$refs.pagination.setPaginationData(paginationData)
    },
    onChangePage (page) {
      this.$refs.vuetable.changePage(page)
    },
    onRowClicked (row) {
      this.showModerationModal(row.data)
    },
    showModerationModal (data) {
      let me = this
      if (this.$store.getters.userInfo) {
        var ComponentClass = Vue.extend(ModerationModal)
        var modalsInstance = new ComponentClass({
          data: {
            user: me.user,
            id: data.id,
            i18n: this.$i18n
          }
        })
        modalsInstance.$mount()
        modalsInstance.$on('refresh', this.refreshPage)
        let detailsContent = modalsInstance.$el
        global.instanceApp.$alertSwal.modalAlert(null, detailsContent, null,
          'no-padding w-auto', { showConfirmButton: false }).then((result) => { })
      }
    },
    onLoading () {
      this.isLoading = true
    },
    onLoaded () {
      this.isLoading = false
      this.$jQuery('table.table').reflowTable()

      if (this.$route.query.process) {
        if (this.$refs.vuetable.$data.tableData) {
          if (this.$refs.vuetable.$data.tableData.length > 0) {
            this.onRowClicked({ data: this.$refs.vuetable.$data.tableData[0] })
          }
        }
      }
    },
    onDelete (id) {
      // this.$alertSwal.modalConfirm('', 'Você tem certeza que deseja excluir este registro?',
      //   'warning', 'Sim', 'Não', (e) => this.deleteValuation(id)).then((result) => {
      //   if (result.value) {
      //     this.$alertSwal.toast('Registro excluído com sucesso!')
      //   } else {
      //     this.$alertSwal.toast('Não foi possível excluir o registro!', 'error')
      //   }

      //   this.$refs.vuetable.refresh()
      // })
    },
    async deleteValuation (id) {
      // let _this = this
      // _this.isLoading = true
      // await ValuationService.deleteValuation(id).then(res => {
      //   // if (res.httpCode === 200 && res.data.status === 'ok') {
      //   _this.isLoading = false
      // }).catch(() => {
      //   _this.isLoading = false
      // })
    },
    /**
     * Carrega a lista de situações possíveis.
     * @param colors {bool} load colors too
     */
    loadStatusList (colors) {
      // this.loading = true

      let filters = {
        'colors': colors
      }

      ValuationService.getStatusList(filters).then(res => {
        this.statusList = res.data.data
        // this.loading = true
      })
    },
    formatStatus (value) {
      if (value === null || value === undefined) {
        return ' - '
      }

      let statusList = this.statusList

      if (statusList.length <= 0) {
        return value
      }

      let colorList = [
        {
          'color': '#f1ba26' // amarelo
        },
        {
          'color': '#5ec65f' // verde
        },
        {
          'color': '#ff0000' // vermelho
        }
      ]

      let color = colorList[value].color
      let label = statusList[value].name

      return '<span style="color: ' + color + '">' + label + '</span>'
    },
    refreshPage () {
      this.$refs.vuetable.refresh()
    }
  },
  computed: {
    user () {
      return this.$store.getters.userInfo || null
    },
    isSuperAdmin () {
      let userInfo = this.$store.getters.userInfo || null
      if (userInfo && userInfo.roles.indexOf('ROLE_SUPER_ADMIN') !== -1) {
        return true
      }
      return false
    },
    isValuationAdmin () {
      let userInfo = this.$store.getters.userInfo || null
      if (userInfo && userInfo.roles.indexOf('ROLE_VALUATION_MANAGER') !== -1) {
        return true
      }
      return false
    }
  }
}
</script>

<style scoped>
</style>
